import { useEffect, useState } from "react";
import logo from '../../../assets/img/logo.svg';
import zapier_logo from '../../../assets/img/zapier_logo.png';
import Box from "elements/Box";
import StoresSelector from "./StoresSelector";
import { LuArrowBigRightDash } from "react-icons/lu";
import { Button, Divider } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { ApiRequest } from "GlobalFunctions";


export default function Oauth(props: any) {

    const { setLoading } = props;
    const [loading, setLoadingState] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);


    const handleSumbit = () => {
        setLoadingState(true);
        const urlParams = new URLSearchParams(window.location.search);
        const redirect_uri = urlParams.get('redirect_uri');
        const state = urlParams.get('state'); // Obtener el state parameter
        
        ApiRequest({
            url: '/admin/oauth/generateCode',
            method: 'POST',
            body: {
                redirect_uri: redirect_uri,
                state: state 
            },
            setResponse: (response: any) => {
                const { status, code } = response;
                if (status === "success") {
                    // Incluir tanto el code como el state en la redirección
                    const redirectUrl = `${redirect_uri}?code=${code}&state=${state}`;
                    window.location.href = redirectUrl;
                } else {
                    setLoadingState(false);
                }
            }
        });
    }

    return (
        <>
        <div className="section-gradient_component">
            <div className="section-gradient_hero-1"></div>
            <div className="section-gradient_hero-2"></div>
            <div className="section-gradient_hero-3"></div>
            <div className="section-gradient_hero-5"></div>
        </div>

        <div id='structure'>
            <div id="main-container">
                <div id="main-content" className="oauth-main-content">
                    <Box id='oauth_box' className='intent-auth_component'>
                        <h1 className="intent-auth_heading text-color-darkpurple">An App Wants to Connect</h1>

                        <div className="flex-content">
                            <div className="connect-logos">
                                <img className='app_logo' src={logo}></img>
                                <LuArrowBigRightDash size={35} color='#5c46e2' />
                                <img className='app_logo' src={zapier_logo}></img>
                            </div>


                        
                        </div>

                        <p className="oauth_disclaimer">By connecting, you allow the app to access your store data. You can disconnect at any time in your store settings.</p>

                            <LoadingButton
                                variant="contained"
                                className="intent-auth_button"
                                onClick={() => {
                                    handleSumbit();
                                }}
                                loading={loading}
                            >
                                Connect
                            </LoadingButton>

                            <Button
                                className="intent-auth_button"
                                onClick={() => {
                                    setLoading(true);
                                }} 
                            >
                                Cancel
                            </Button>
                    </Box>
                </div>
            </div>
        </div>
    </>

    );
}