import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from '@mui/material';
import ISwitch from 'elements/ISwitch';
import { ApiRequest } from 'GlobalFunctions';
import { useSnackbar } from "notistack";
import { FaLink } from "react-icons/fa";
import { ProjectConfig } from 'Global';

const defaultPaymentSettings: any = {
    test_mode: true,
    elavon: {
        active: false,
        ssl_pin: "",
        ssl_user_id: "",
        ssl_merchant_id: ""
    },
    stripe: {
        active: false,
        public_key: "",
        secret_key: "",
        webhook_secret: ""
    },
    authorize_net: {
        active: false,
        api_login_id: "",
        transaction_key: ""
    },
    square: {
        active: false,
        access_token: "",
        refresh_token: ""
    }
};

export default function PaymentMethodForm(props: any) {
    const { initialPaymentSettings = {} } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [payment_settings, setPaymentSettings] = useState(defaultPaymentSettings);
    const [selectedMethod, setSelectedMethod] = useState<string>("");

    useEffect(() => {
        getPaymentSettings();

        // Add event listener for Square OAuth popup messages
        const handleMessage = (event: MessageEvent) => {
            if (event.data.type === 'SQUARE_AUTH_SUCCESS') {
                getPaymentSettings();
                enqueueSnackbar('Square account linked successfully', { variant: 'success' });
            } else if (event.data.type === 'SQUARE_AUTH_ERROR') {
                enqueueSnackbar('Failed to link Square account', { variant: 'error' });
            }
        };

        window.addEventListener('message', handleMessage);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    /*=============================================
    GET PAYMENT SETTINGS
    =============================================*/
    const getPaymentSettings = () => {
        ApiRequest({
            url: '/stores/getPaymentSettings',
            method: 'GET',
            setResponse: (response: any) => {
                if (response.status === "success") {
                    const settings = response.data;
                    const activeMethod = Object.keys(settings).find(
                        (key) => key !== 'test_mode' && settings[key]?.active
                    );
                    if (activeMethod) {
                        setSelectedMethod(activeMethod);
                    }
                    setPaymentSettings(settings);
                }
            }
        });
    };

    /*=============================================
    METHOD CHANGE
    =============================================*/
    const handleMethodChange = (event: any) => {
        setSelectedMethod(event.target.value);
        const prevSettings = { ...payment_settings };
        
        // Reset all payment gateways active status
        Object.keys(prevSettings).forEach((key) => {
            if (key !== 'test_mode') {
                prevSettings[key].active = false;
            }
        });

        // Reset Square tokens if selecting different method
        if (event.target.value !== 'square') {
            prevSettings.square = {
                ...prevSettings.square,
                active: false,
                accessToken: '',
                refreshToken: ''
            };
        }

        const updatedSettings = {
            ...prevSettings,
            [event.target.value]: {
                ...prevSettings[event.target.value],
                active: true
            }
        };
        
        setPaymentSettings(updatedSettings);
    };

    /*=============================================
    FIELD CHANGE
    =============================================*/
    const handleFieldChange = (event: any, field: string) => {
        if (selectedMethod) {
            setPaymentSettings({
                ...payment_settings,
                [selectedMethod]: {
                    ...payment_settings[selectedMethod],
                    [field]: event.target.value
                }
            });
        }
    };

    /*=============================================
    HANDLE TEST MODE CHANGE
    =============================================*/
    const handleTestModeChange = (e: any) => {
        const newTestMode = e.target.checked;
        setPaymentSettings((prevSettings: any) => {
            return { ...prevSettings, test_mode: newTestMode };
        });
    };

    /*=============================================
    SUBMIT
    =============================================*/
    const handleSubmit = async () => {
        ApiRequest({
            url: '/stores/updatePaymentSettings',
            method: 'POST',
            body: {
                payment_settings
            },
            setResponse: (response: any) => {
                if (response.status === "success") {
                    enqueueSnackbar('Payment settings updated successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar('An error occurred while updating payment settings', { variant: 'error' });
                }
            }
        });
    };


    /*=============================================
    HANDLE SQUARE OAUTH POPUP
    
    =============================================*/
    const handleSquareLink = () => {
        const client_id = ProjectConfig.SQUARE_CLIENT_ID;
        const redirect_uri = ProjectConfig.SQUARE_REDIRECT_URI;

        const state = localStorage.getItem('store_id');
        const baseUrl = ProjectConfig.SQUARE_BASE_URL + "/oauth2/authorize";
        let scopes = "PAYMENTS_WRITE"
        scopes += "+PAYMENTS_READ"
        scopes += "+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS"
        const redirectUri = encodeURIComponent(redirect_uri); // Codifica la redirect_uri
        
        const oauthUrl = `${baseUrl}?client_id=${client_id}&scope=${scopes}&state=${state}&redirect_uri=${redirectUri}`;
        
        // Abrir un popup para el flujo de autenticación OAuth
        const width = 500;
        const height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        window.open(
            oauthUrl,
            'square-auth',
            `width=${width},height=${height},top=${top},left=${left}`
        );
    };

    return (
        <div className="">
            <div className="account_row">
                <span className="account_label">Testing Mode</span>
                <ISwitch
                    label="Test Mode"
                    checked={payment_settings.test_mode}
                    onChange={handleTestModeChange}
                />
            </div>

            {!payment_settings.test_mode && (
            <>
                <div className="account_row">
                    <span className="account_label">Payment Processor</span>
                    <FormControl fullWidth margin="normal" disabled={payment_settings.test_mode}>
                        <InputLabel>Select Payment Method</InputLabel>
                        <Select
                            value={selectedMethod}
                            onChange={handleMethodChange}
                            label="Select Payment Method"
                            size="small"
                        >
                            <MenuItem value="elavon">Elavon</MenuItem>
                            <MenuItem value="stripe">Stripe</MenuItem>
                            <MenuItem value="authorize_net">Authorize.net</MenuItem>
                            <MenuItem value="square">Square</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            

                {selectedMethod === 'elavon' && (
                    <>
                        <div className="account_row">
                            <span className="account_label">Pin</span>
                            <TextField
                                size="small"
                                value={payment_settings.elavon.ssl_pin}
                                onChange={(e) => handleFieldChange(e, 'ssl_pin')}
                                fullWidth
                                margin="normal"
                                disabled={payment_settings.test_mode}
                            />
                        </div>
                        <div className="account_row">
                            <span className="account_label">User ID</span>
                            <TextField
                                size="small"
                                value={payment_settings.elavon.ssl_user_id}
                                onChange={(e) => handleFieldChange(e, 'ssl_user_id')}
                                fullWidth
                                margin="normal"
                                disabled={payment_settings.test_mode}
                            />
                        </div>
                        <div className="account_row">
                            <span className="account_label">Merchant ID</span>
                            <TextField
                                size="small"
                                value={payment_settings.elavon.ssl_merchant_id}
                                onChange={(e) => handleFieldChange(e, 'ssl_merchant_id')}
                                fullWidth
                                margin="normal"
                                disabled={payment_settings.test_mode}
                            />
                        </div>
                    </>
                )}

                {selectedMethod === 'stripe' && (
                    <>
                        <div className="account_row">
                            <span className="account_label">Public Key</span>
                            <TextField
                                size="small"
                                value={payment_settings.stripe.public_key}
                                onChange={(e) => handleFieldChange(e, 'public_key')}
                                fullWidth
                                margin="normal"
                                disabled={payment_settings.test_mode}
                            />
                        </div>
                        <div className="account_row">
                            <span className="account_label">Secret Key</span>
                            <TextField
                                size="small"
                                value={payment_settings.stripe.secret_key}
                                onChange={(e) => handleFieldChange(e, 'secret_key')}
                                fullWidth
                                margin="normal"
                                disabled={payment_settings.test_mode}
                            />
                        </div>
                        <div className="account_row">
                            <span className="account_label">Webhook Secret</span>
                            <TextField
                                size="small"
                                value={payment_settings.stripe.webhook_secret}
                                onChange={(e) => handleFieldChange(e, 'webhook_secret')}
                                fullWidth
                                margin="normal"
                                disabled={payment_settings.test_mode}
                            />
                        </div>
                    </>
                )}
                
                {selectedMethod === 'authorize_net' && (
                    <>
                        <div className="account_row">
                            <span className="account_label">API Login ID</span>
                            <TextField
                                size="small"
                                value={payment_settings.authorize_net.api_login_id}
                                onChange={(e) => handleFieldChange(e, 'api_login_id')}
                                fullWidth
                                margin="normal"
                                disabled={payment_settings.test_mode}
                            />
                        </div>
                        <div className="account_row">
                            <span className="account_label">Transaction Key</span>
                            <TextField
                                size="small"
                                value={payment_settings.authorize_net.transaction_key}
                                onChange={(e) => handleFieldChange(e, 'transaction_key')}
                                fullWidth
                                margin="normal"
                                disabled={payment_settings.test_mode}
                            />
                        </div>
                    </>
                )}
                {selectedMethod === 'square' && (
                    <div className="account_row">
                        <span className="account_label"></span>
                        {payment_settings.square.accessToken ? (
                            <div style={{ marginTop: '20px' }}>
                                <div style={{ color: 'green', marginBottom: '10px' }}>
                                    ✓ Square account successfully linked
                                </div>
                                <small style={{ color: '#666' }}>
                                    Your Square account is connected and ready to process payments
                                </small>
                            </div>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginTop: '20px' }}
                                startIcon={<FaLink />}
                                onClick={handleSquareLink}
                            >
                                Link Square Account
                            </Button>
                        )}
                    </div>
                )}
            </>
            )}
            {/* Submit button - Show in test mode or when not Square */}
            {(payment_settings.test_mode || selectedMethod !== 'square') && (
                <div className="account_row">
                    <span className="account_label"></span>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '20px' }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            )}
        </div>
    );
}
