import React, { useEffect, useState } from "react";
import { Button, TextField, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import InvoicePreview from "./InvoicePreview";
import axios from "axios";
import { ProjectConfig } from "Global";
import PopoverPicker from "./Components/ColorPicker";
import ImageUploader from "../../../elements/ImageUploader";
import { ApiRequest } from "GlobalFunctions";
import AutocompleteField from "elements/AutocompleteField";

export default function InvoiceEditor(props: any) {
    const { setLoading } = props;
    const [files, setFiles] = useState<any>([]);
    const [errors, setErrors] = useState<any>({});
    const defaultFormValues = {
        custom_invoice_id: null,
        image: "",
        address: "",
        country_id: "",
        region_id: "",
        city_id: "",
        state: "",
        zip_code: "",
        email: "",
        color: "#7963ff",
    };

    const [formValues, setFormValues] = useState<any>(defaultFormValues);
    const [countries, setCountries] = useState([]);

    const getCountries = async () => {
        await ApiRequest({
            url: '/countries/getCountries',
            method: 'GET',
            setResponse: (response: any) => {
                setCountries(response);
            }
        });
    }

    useEffect(() => {
        getCountries();
        setLoading(false);
    }, []);

    const validateForm = () => {
        const newErrors: any = {};
        
      
        if (!formValues.email) newErrors.email = 'Support email is required';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (!validateForm()) {
            return;
        }
        setLoading(true);

        const body = new FormData();
        if (files) {
            body.append("image", files);
        }
        body.append("formValues", JSON.stringify(formValues));

        axios.post(ProjectConfig.api_url + "/customInvoices/addEdit", body, {
            headers: {
                "Content-Type": "multipart/form-data",
                store_id: localStorage.getItem("store_id"),
            },
        })
            .then((response) => {
                console.log(response.data);
            })
            .catch((err) => {
                console.log(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getDetails = () => {

        ApiRequest({
            url: '/customInvoices/details',
            method: 'GET',
            query: {
                store_id: localStorage.getItem("store_id"),
            },
            setResponse: (response: any) => {
                if (response.data.status != "error") 
                {
                    setFormValues(response.data);
                }
            }
        });

        setFiles([]);
    };

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <div className="ModuleContainer">
            <div className="ModuleHeader">
                <div className="ModuleTitle">
                    <h1>Custom Invoice</h1>
                </div>
            </div>

            <div id="invoice_editor" className="ModuleContent">
                <div id="invoice_inputs">
                    <div className="invoice_section">
                        <h2>Logo</h2>
                        <div className="drawerImagesContainer">
                            <ImageUploader 
                                image={formValues.image} 
                                setImage={(newImage) => setFormValues({ ...formValues, image: newImage })} 
                                setFiles={setFiles} 
                            />
                        </div>
                        <div className="invoice_logo_text_1">Add or change the company logo on your invoice</div>
                        <div className="invoice_logo_text_2">Recommended Size: 240px x 100px</div>
                    </div>

                    <div className="invoice_section">
                        <h2>Company Information</h2>
                        <div className="input_group">
                            <TextField
                                fullWidth
                                label="Business Address"
                                variant="outlined"
                                value={formValues.address}
                                onChange={(e) => setFormValues({ ...formValues, address: e.target.value })}
                            />
                            
                            <div className="input_row">
                                <FormControl fullWidth error={!!errors.country_id}>
                                    <InputLabel id="country-label">Business Country</InputLabel>
                                    <Select
                                        labelId="country-label"
                                        id="country"
                                        value={formValues.country_id}
                                        label="Country"
                                        name="country"
                                        onChange={(e) => {
                                            setFormValues({
                                                ...formValues,
                                                country_id: e.target.value,
                                                region_id: '',
                                                city_id: ''
                                            })
                                        }}
                                    >
                                        {countries.map((country: any, index: number) => (
                                            <MenuItem key={index} value={country.country_id}>
                                                {country.country_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            {formValues.country_id && formValues.country_id !== '' && (
                                <AutocompleteField
                                    label="Business State / Province"
                                    endpoint="/countries/getRegions"
                                    endpoint_params={{ country_id: formValues.country_id }}
                                    value={formValues.region_id}
                                    onChange={(data: any) => {
                                        setFormValues({
                                            ...formValues,
                                            region_id: data ? data.value : '',
                                            city_id: ''
                                        })
                                    }}
                                    fullWidth
                                    error={!!errors.region_id}
                                    helperText={errors.region_id}
                                />
                            )}

                            <TextField
                                fullWidth
                                label="Zip Code"
                                variant="outlined"
                                value={formValues.zip_code}
                                onChange={(e) => setFormValues({ ...formValues, zip_code: e.target.value })}
                            />
                            
                            <TextField
                                fullWidth
                                label="Support Email"
                                variant="outlined"
                                value={formValues.email}
                                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                error={!!errors.email}
                                helperText={errors.email}
                            />
                        </div>
                    </div>

                    <div className="invoice_section">
                        <h2>Brand Color</h2>
                        <span className="invoice_logo_text_1">Select Your Color</span>
                        <PopoverPicker
                            color={formValues.color}
                            onChange={(newColor) => setFormValues({ ...formValues, color: newColor })}
                        />
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{ width: "fit-content" }}
                    >
                        Update Invoice
                    </Button>
                </div>
                <InvoicePreview formValues={formValues} />
            </div>

            <div className="invoice-background pointer-events-off"></div>
        </div>
    );
}
