import axios from 'axios';
import { useEffect, useState } from 'react'
import { Avatar, Button, CircularProgress, Dialog, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { ProjectConfig } from 'Global';
import AlertDialog from 'elements/AlertDialog';
import CouponEditor from './CouponEditor';
import ListPagination from 'elements/ListPagination';
import 'assets/css/main.css';
import { ApiRequest } from 'GlobalFunctions';

export default function PlanList(props: any) {

  const { setLoading } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });
  const currentPage = parseInt(localStorage.getItem('page') || "1");
  const [page, setPage] = useState(currentPage);
  const [openEditor, setOpenEditor] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = () => {
    setLoading(true);

    ApiRequest({
      method: 'GET',
      url: '/coupons/list',
      query: { page: page },
      setResponse: (response: any) => {
        setList(response.list);
        setPagination(response.pagination);
        setLoading(false);
      }
    })
  }

  /*=========================================================
  DELETE ITEM
  =========================================================*/
  const handleDelete = (coupon_id: number) => {
    handleMenuClose();
    setActiveItem(coupon_id);
    setOpenAlert(true);
  }

  const deleteItem = () => {
    const body = { coupon_id: activeItem };
    axios.post(ProjectConfig.api_url + "/coupons/deleteItem", body)
    .then((response) => {
      console.log("delete response", response.data);
        if (response.data.status === "success") {
            enqueueSnackbar('Item deleted successfully', { variant: 'success' });
            loadList();
        }
    })
    .catch((err) => {
        console.log(err.data);
    })
    .then(() => {
        setOpenAlert(false);
    });
  }

  /*=========================================================
  HANDLE MENU OPEN
  =========================================================*/
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setMenuAnchor(event.currentTarget);
    setActiveItem(item);
  };

  /*=========================================================
  HANDLE MENU CLOSE
  =========================================================*/
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  /*=========================================================
  HANDLE EDIT
  =========================================================*/
  const handleEdit = () => {
    setOpenEditor(true);
    handleMenuClose();
  };

  return (
    <div className='ModuleContainer'>
        <div className="ModuleHeader">
          <div className="ModuleTitle">
            <h1>Coupons</h1>
          </div>
          <div className="ModuleActions">
            <Button
              variant="outlined"
              color="info"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true);
              }}
              className="btnList"
              startIcon={<AddIcon />}
            >
              New Coupon
            </Button>
          </div>
        </div>

        
        <div className='ModuleContent'>
          <TableContainer sx={{ width: "100%" }}>
            <Table className="table">
              <TableHead> 
                <TableRow>
                  <TableCell>Coupon Name</TableCell>
                  <TableCell>Coupon Code</TableCell>
                  <TableCell>Terms</TableCell>
                  <TableCell align="right">Redemptions</TableCell>
                  <TableCell>Expires</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, i) => {

                const terms = item.conditions.type === 'free' 
                  ? 'Free' 
                  : (item.conditions.type === 'percentage' 
                    ? item.conditions.amount + '%' 
                    : '$' + item.conditions.amount);

               
                const enableEndDate = item.conditions.redemptionLimits.enableEndDate; 
                const expires = enableEndDate ? item.conditions.redemptionLimits.endDate : '---';
                  return (
                  <TableRow key={i} hover role="checkbox">
                    <TableCell>{item.coupon_name}</TableCell>
                    <TableCell>{item.coupon_code}</TableCell>
                    <TableCell>{terms}</TableCell>
                    <TableCell align="right">{item.redemptions}</TableCell>
                    <TableCell>{expires}</TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, item)}>
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor && activeItem && activeItem.coupon_id === item.coupon_id)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={() => {
                          setActiveItem(item);
                          setOpenEditor(true);
                          handleMenuClose();
                        }}>
                          <EditIcon fontSize="small" />
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(item.coupon_id)}>
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                          Remove
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </TableContainer>

          <ListPagination
            pagination={pagination}
            page={page}
            onChange={(event: any, value: any) => {
              setPage(value);
            }}
            label="Coupons"
            style={{ marginTop: "20px", alignSelf: "center" }}
          />
        </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to delete this item?"
        send={deleteItem}
      />

      <CouponEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        coupon_id={activeItem ? activeItem.coupon_id : null}
        loadList={loadList}
      />
    </div>
  );
};
