import { Avatar, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Radio, ListItem, RadioGroup, FormControl, FormLabel, FormControlLabel, Skeleton } from '@mui/material';
import { ApiRequest } from 'GlobalFunctions';
import { useEffect, useState } from 'react';
import { PiUserCircleLight } from "react-icons/pi";
import { GiExitDoor } from "react-icons/gi";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { set } from 'lodash';
import { color } from '@mui/system';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function StoresSelector(props: any) {
    const { store_id, setStoreId } = props;
    const navigate = useNavigate();
    const [displayStores, setDisplayStores] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GetStores();
    }, []);

    const GetStores = async () => {
        setLoading(true);
        ApiRequest({
            url: '/stores/getStores',
            method: 'GET',
            setResponse: (response: any) => {
                console.log("RESPONSE", response);
                setDisplayStores(response);
                setLoading(false);
            }
        });
    };

    const changeStore = (selected_store_id: any) => {
        setStoreId(selected_store_id);
        console.log("STORE ID", selected_store_id);
    };

    return (
        <FormControl className='stores_selector'>
            <h2 id="stores-selector-label">Store to connect</h2>
            <p>Select the store you want to connect to this app</p>
            <RadioGroup
                aria-labelledby="stores-selector-label"
                name="stores-selector-group"
                value={store_id || ''}
            >
                {loading ? (
                    Array.from(new Array(3)).map((_, index) => (
                        <Skeleton animation="wave" key={index} variant="rectangular"  height={30} style={{ marginBottom: 8, borderRadius:8 }} />
                    ))
                ) : (
                    displayStores.map((store: any, index: number) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Radio
                                    checked={store_id === store.store_id}
                                    onChange={() => changeStore(store.store_id)}
                                />
                            }
                            label={store.store_name}
                            value={store.store_id}
                        />
                    ))
                )}
            </RadioGroup>
        </FormControl>
    );
}

