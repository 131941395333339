export const ProjectConfigOverride = {
  api_url: "https://api.pennicart.io",
  WEBFLOW_CLIENT_ID: "b3c735782d6709af53127172338909c8d41975d0a33278f9d6e3a948eea519d7",

    /*============================================
    SQUARE
    =============================================*/
    SQUARE_CLIENT_ID: "sq0idp-Lg8l2z85aGBaaQOudQa7Zg",
    SQUARE_BASE_URL: "https://connect.squareup.com",
    SQUARE_REDIRECT_URI: "https://api.pennicart.io/square/auth",
};